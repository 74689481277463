import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { Snackbar } from "@surya-digital/leo-reactjs-material-ui";
import {
  EMPTY_CHARACTER,
  MAXIMUM_GRID_COLUMN_OPTIONS,
  MAXIMUM_GRID_MULTI_SELECT_COLUMN_OPTIONS,
  MAXIMUM_SINGLE_SELECT_RATING_SCALE_SLIDER_OPTIONS,
  MAX_GRID_COLUMNS,
  MAX_MULTI_CHOICE_OPTIONS,
  MIN_MULTI_CHOICE_OPTIONS,
  MIN_RANKING_OPTIONS,
  SINGLE_SELECT_NPS_OPTIONS_COUNT,
} from "@pulse/shared-components";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";

interface EmptyFieldSnackbarProps {
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  t: TFunction;
  isSaveButtonClicked: boolean;
  setIsSaveButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmptyFieldSnackbar = observer(
  ({
    questionDetailsStore,
    t,
    isSaveButtonClicked,
    setIsSaveButtonClicked,
  }: EmptyFieldSnackbarProps): React.ReactElement => {
    const [emptyFieldBannerMessage, setEmptyFieldBannerMessage] = useState<
      string | undefined
    >();

    const minMaxSelection = (): number => {
      return questionDetailsStore.isMultiChoiceTypeQuestion
        ? MIN_MULTI_CHOICE_OPTIONS
        : MIN_RANKING_OPTIONS;
    };

    useEffect(() => {
      // Determine the appropriate message based on validation state
      if (questionDetailsStore.fieldValidations) {
        if (questionDetailsStore.isMaxSelectionExceeded) {
          setEmptyFieldBannerMessage(
            t("surveys.addSurveyQuestion.ensureMaxSelection"),
          );
        } else if (questionDetailsStore.isMaxSelectionZero) {
          setEmptyFieldBannerMessage(
            t("surveys.addSurveyQuestion.ensureMaxSelectionIsNotLessThan", {
              minimumMaxSelection: minMaxSelection(),
            }),
          );
        } else if (questionDetailsStore.areQuestionAndOptionCodesNotUpdatable) {
          setEmptyFieldBannerMessage(
            t(
              "surveys.addSurveyQuestion.ensureQuestionCodeAndOptionCodesNotUpdated",
            ),
          );
        } else if (questionDetailsStore.isNPSOptionsCountInvalid) {
          setEmptyFieldBannerMessage(
            t("surveys.addSurveyQuestion.ensureNPSDoesNotExceed", {
              npsOptionsCount: SINGLE_SELECT_NPS_OPTIONS_COUNT,
            }),
          );
        } else if (questionDetailsStore.isMaxRatingScaleSliderOptionReached) {
          setEmptyFieldBannerMessage(
            t(
              "surveys.addSurveyQuestion.ensureRatingScaleOrSliderDoesNotExceed",
              {
                maxRatingScaleSliderOptions:
                  MAXIMUM_SINGLE_SELECT_RATING_SCALE_SLIDER_OPTIONS,
              },
            ),
          );
        } else if (questionDetailsStore.isInvalidGridNPSSelected) {
          setEmptyFieldBannerMessage(
            t("surveys.addSurveyQuestion.ensureNPSNotSelectedInGrid"),
          );
        } else if (questionDetailsStore.isMaxGridSizeReached) {
          setEmptyFieldBannerMessage(
            t("surveys.addSurveyQuestion.ensureGridWidthNotExceeded", {
              maxGridWidth: MAX_GRID_COLUMNS,
            }),
          );
        } else if (questionDetailsStore.isMaxGridRadioButtonCountReached) {
          setEmptyFieldBannerMessage(
            t(
              "surveys.addSurveyQuestion.ensureMaxRadioButtonCountNotExceeded",
              {
                maxRadioButtonCount: MAXIMUM_GRID_COLUMN_OPTIONS,
              },
            ),
          );
        } else if (
          questionDetailsStore.isMaxGridMultiChoiceOptionsCountReached
        ) {
          questionDetailsStore.isGridQuestion
            ? setEmptyFieldBannerMessage(
                t(
                  "surveys.addSurveyQuestion.ensureMaxMultiChoiceOptionsCountNotExceeded",
                  {
                    maxMultiChoiceOptions:
                      MAXIMUM_GRID_MULTI_SELECT_COLUMN_OPTIONS,
                  },
                ),
              )
            : setEmptyFieldBannerMessage(
                t(
                  "surveys.addSurveyQuestion.ensureMaxMultiChoiceOptionsCountNotExceeded",
                  {
                    maxMultiChoiceOptions: MAX_MULTI_CHOICE_OPTIONS,
                  },
                ),
              );
        } else {
          setEmptyFieldBannerMessage(
            t("surveys.addSurveyQuestion.ensureRequiredFields"),
          );
        }
      } else if (questionDetailsStore.isQuestionCodeNotUnique) {
        setEmptyFieldBannerMessage(
          t("surveys.addSurveyQuestion.ensureQuestionCodeUnique"),
        );
      } else if (questionDetailsStore.isOptionCodeDuplicate) {
        setEmptyFieldBannerMessage(
          t("surveys.addSurveyQuestion.ensureOptionCodeUnique"),
        );
      } else {
        setEmptyFieldBannerMessage(undefined);
      }
    }, [
      questionDetailsStore.fieldValidations,
      questionDetailsStore.isQuestionCodeNotUnique,
      questionDetailsStore.isOptionCodeDuplicate,
      isSaveButtonClicked,
      t,
    ]);

    return (
      <Snackbar
        onClose={(): void => {
          setEmptyFieldBannerMessage(undefined);
          setIsSaveButtonClicked(false);
        }}
        isOpen={isSaveButtonClicked && Boolean(emptyFieldBannerMessage)}
        message={emptyFieldBannerMessage ?? EMPTY_CHARACTER}
        severity={"error"}
        isDismissible={true}
      />
    );
  },
);
